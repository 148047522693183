<!-- 首页 -->
<template>
    <div class="home">
        <el-container>
            <!-- 左边 -->
            <el-aside :class="isCollapse?'scrollbar':'not-text'">
                <el-scrollbar :class="isCollapse?'scrollbar':''">
                    <!-- logo -->
                    <img :src='logoimg' class="img">
                    <!-- 菜单搜索 -->
                    <!-- <div id="ipt">
                    <el-input v-model="searchNav" class="input" suffix-icon="el-icon-search" placeholder="搜索菜单">
                     </el-input>
                </div> -->
                    <el-menu :default-active="onRouts" class="el-menu-vertical-demo" @open="handleOpen"
                        @close="handleClose" background-color="#29726C" text-color="#fff" router :unique-opened="true"
                        :collapse="isCollapse" active-text-color="#ffffff" :class="isCollapse?'scrollbar':''"
                        :collapse-transition="false">
                        <el-submenu v-for="(item,index) in nav" :index="item.path" :hide-timeout="0" :key="index">
                            <template slot="title">
                                <i :class="item.icon" style="color: #fff;"></i>
                                <span>{{item.label}}</span>
                            </template>
                            <el-menu-item-group v-for="(items,idx) in item.children" v-show="items.noCache" :key="idx">
                                <el-menu-item :index="item.path+'/'+items.path">
                                    {{ items.label }}
                                </el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                        <el-menu-item class="setup" @click="websetup">
                        	<i class="el-icon-s-tools"></i>
                        	<span slot="title">官网设置</span>
                        </el-menu-item>
                    </el-menu>
                </el-scrollbar>
            </el-aside>
            <!-- 右边 -->
            <el-main :style="{width:(fullWidth-230)+'px'}">
                <el-container>
                    <el-header
                        style="display: flex;justify-content: space-between;flex-wrap: wrap;position: relative;z-index: 5;">
                        <div class="" style='height: 50px; display: flex;align-items: center;'>
                            <span class="span_1" :class="rotate?'cartoon':'timing'" style="display: flex;align-items: center;
                            justify-content: center;" @click="toggleClick()">
                                <img src="../../assets/menuIcon.png">
                            </span>
                            <!-- 面包屑,历史记录 -->
                            <el-breadcrumb separator="" id="breadcrumb">
                                <el-breadcrumb-item class="home-route" v-if="bool" :to="{ path: '/dashboard' }">
                                    <i class="el-icon-s-home"></i>
                                    <span style="display: inline-block;padding: 0px 0px 0px 5px;">首页</span>
                                </el-breadcrumb-item>
                                <el-breadcrumb-item
                                    style="line-height: 50px;display: inline-block; width:200px;text-align: center;"
                                    v-if="bool" v-for="(item,index) in routeList" :key="index">
                                    {{item}}
                                </el-breadcrumb-item>
                            </el-breadcrumb>

                        </div>
                        <!-- 下拉部分 -->
                        <div class="t-right">
                            <img src="../../assets/rightImg.png" style="cursor: default;">
                            <div class="" style="padding:0px 15px;">
                                {{ userinfo.nickname }}
                            </div>
                            <!-- 下拉菜单 -->
                            <el-dropdown trigger="click">
                                <span class="el-dropdown-link">
                                    <i class="el-icon-arrow-down"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" style="padding: 0px;">
                                    <el-dropdown-item class="personal" @click.native="personal">个人信息</el-dropdown-item>
                                    <el-dropdown-item class="modify" @click.native="dialog=true">修改密码</el-dropdown-item>
                                    <el-dropdown-item class='quit' @click.native="loginOut">退出系统</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </el-header>
                    <!-- 个人信息弹出框 -->
                    <el-dialog title="个人信息" :visible.sync="dialogFormVisible">
                        <el-divider></el-divider>
                        <div class="" style="width: 400px;margin: 0px auto;color: #000000;">
                            <div class="txtbox">
                                <span>用户姓名：</span>
                                <input type="text" class="itp" :value="userForm.userName" disabled="disabled" />
                            </div>
                            <div class="txtbox">
                                <span>用户账号：</span>
                                <input type="text" class="itp" :value="userForm.userAccount" disabled="disabled" />
                            </div>
                            <div class="txtbox">
                                <span>手机号码：</span>
                                <input type="text" class="itp" :value="userForm.phoneNumber" disabled="disabled" />
                            </div>
                            <div class="txtbox">
                                <span>所在部门：</span>
                                <input type="text" class="itp" :value="userForm.department" disabled="disabled" />
                            </div>
                        </div>
                        <el-divider></el-divider>
                        <div class="footer">
                            <button type="button" class="guanbi" @click="dialogFormVisible=false">关闭</button>
                        </div>
                    </el-dialog>
                    <!-- 修改密码弹出框 -->
                    <el-dialog title="修改密码" :visible.sync="dialog">
                        <el-divider></el-divider>
                        <el-form :label-position="labelPosition" :rules="rules" ref="modify" class="form-1"
                            label-width="120px" :model="modify">
                            <el-form-item label="旧密码" prop="oldPwd">
                                <el-input type="text" v-model="modify.oldPwd" show-password auto-complete="off"
                                    placeholder="密码格式由数字和字母组成，长度为6位以上">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="新密码" prop="newPwd">
                                <el-input type="text" v-model="modify.newPwd" show-password auto-complete="off"
                                    placeholder="密码格式由数字和字母组成，长度为6位以上">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="重复新密码" prop="repeatNewPwd">
                                <el-input type="text" v-model="modify.repeatNewPwd" show-password auto-complete="off"
                                    placeholder="密码格式由数字和字母组成，长度为6位以上">
                                </el-input>
                            </el-form-item>
                        </el-form>
                        <el-divider></el-divider>
                        <div class=""  style="text-align: right;padding-right: 20px;padding-bottom: 20px;">
                            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('modify')">
                                提交 </el-button>
                            <el-button style="background-color: #f5f5f5;color: #666;" @click="resetForm('modify')">取消
                            </el-button>
                        </div>
                    </el-dialog>

                    <!-- 视图窗口 -->
                    <el-main class="dds" style="margin:10px 20px; background-color: #FFFFFF;">
                        <router-view></router-view>
                    </el-main>
                </el-container>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import {
        permissionRouter
    } from '@/router'
    // import nav from '../../utils/surface.js'
    export default {
        name: 'Home',
        components: {

        },
        data() {
            // 确认密码正则
            // var validatePass = (rule, value, callback) => {
            //     if (value === '') {
            //         callback(new Error('请再次输入密码'));
            //     } else if (value !== this.modify.newPwd) {
            //         callback(new Error('两次输入密码不一致!'));
            //     }
            // }
            return {
                userinfo: {}, //账号信息
                logoimg: require("../../assets/logo1@2x.png"),
                searchNav: "", //搜索菜单 
                nav:[],//左侧列表
                rotate: false, //判断是否旋转
                isCollapse: false, //判断是否展开
                flag: true,
                bool: true, //判断面包屑
                fullWidth: document.documentElement.clientWidth, //屏幕宽度
                checked: '',
                routeList: [],
                labelPosition: 'right',
                dialogFormVisible: false,
                userForm: { //个人信息
                    userName: "", //用户姓名
                    userAccount: "", //用户账号
                    phoneNumber: "", //手机号码
                    department: "", //所在部门
                },
                dialog: false, //修改密码弹框
                modify: {
                    oldPwd: "", //旧密码
                    newPwd: "", //新密码
                    repeatNewPwd: "" //重复新密码
                },
                judge:false,
                rules: {
                    oldPwd: [{
                        required: true,
                        message: '旧密码不能为空',
                        trigger: 'blur'
                    }, ],
                    newPwd: [{
                            required: true,
                            message: '新密码不能为空',
                            trigger: 'blur'
                        },
                        {
                            pattern: /^[a-zA-Z0-9]{6,}$/,
                            message: '密码格式由数字和字母组成，长度为6位以上',
                            trigger: 'blur'
                        },
                    ],
                    repeatNewPwd: [{
                            required: true,
                            validator:(rule, value, callback)=>{
                                if (value === '') {
                                    console.log('请再次输入密码')
                                    callback(new Error('请再次输入密码'));
                                } else if (value !== this.modify.newPwd) {
                                    callback(new Error('两次输入密码不一致!'));
                                } else {
                                    callback()
                                }
                            
                            } ,
                            trigger: 'blur'
                        },
                    ]
                },
                
            }
        },
        created() {
            const userinfo = JSON.parse(localStorage.getItem("userinfo"))
            this.userinfo = userinfo
            this.nav = userinfo.power
        },
        mounted() {
            // console.log(this.getRoutes,"菜单数据")
            // this.nav= this.getRoutes
            // 监听屏幕变化改变布尔值
            window.onresize = () => {
                    // console.log(document.documentElement.clientWidth)
                    if (document.documentElement.clientWidth <= 1024) {
                        this.isCollapse = true
                    }
                },
                this.crumbs()

        },
        methods: {
            handleOpen(key, keyPath) {
                // console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                // console.log(key, keyPath);
            },
            toggleClick() { //点击图片旋转 左侧菜单弹入弹出
                this.isCollapse = !this.isCollapse
                this.rotate = !this.rotate
            },
            // empty() { //清空面包屑数据数组
            // 	this.routeList = []
            // },
            // 面包屑
            crumbs() {
                let arr = []
                if (this.$route.path == '/dashboard') {
                    this.routeList = []
                }
                this.checked = this.$route.path //赋值路由地址
                this.nav.forEach((item, index) => {
                    // console.log(item)
                    item.children.forEach((e, i) => {
                        // console.log(e)
                        if (item.path + '/' + e.path == this.$route.path) {
                            arr.push(e.label)
                            // arr.push(e.path)
                            this.routeList = arr
                        }
                    })
                })
            },
            personal() { //打开个人信息
                this.dialogFormVisible = true
                this.$request({
                    url:'/api/Humanresources/myinfo',
                    method:'POST',
                    data:{}
                }).then(res=>{
                    if(res.code==1){
                        let data = res.data
                        this.userForm.userName=data.fullname //用户姓名
                        this.userForm.userAccount=data.username //用户账号
                        this.userForm.phoneNumber=data.mobile//手机号码
                        this.userForm.department=data.department //所在部门
                    }
                })
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        if(this.judge){
                            return
                        }
                        this.judge=true
                        setTimeout(() => {
                            this.judge=false
                        }, 1500)
                        this.$request({
                            url:'/api/humanresources/mysave',
                            method:'POST',
                            data:{
                                new_pwd:this.modify.newPwd,//新密码
                                old_pwd:this.modify.oldPwd//旧密码
                            }
                        }).then(res=>{
                            if(res.code==1){
                                this.dialog=false
                                this.$message({
                                    message: '修改密码成功,请重新登录！',
                                    type: 'success'
                                })
                                localStorage.removeItem("userinfo")
                                this.$router.replace({
                                    path: "/login"
                                })
                            } else {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        }).catch(()=>{
                            this.judge=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(modify) { //取消修改密码
                this.$refs[modify].resetFields();
                this.dialog = false
            },
            loginOut(){//退出登录
                this.$request({
                    url:'/api/user/logout',
                    method: 'POST',
                    data:{}
                }).then(res=>{
                    console.log(res)
                    if(res.code==1){
                        this.$message({
                            message: '退出成功',
                            type: 'success'
                        })
                        this.$cookies.remove('token')
                        localStorage.removeItem("userinfo") 
                        this.$router.replace({
                            path: "/login"
                        })
                    }
                })
            },
            websetup(){//官网设置
                window.open('https://www.szart.cn/api/public/GeHOLthYTa.php/index/login')
            }
        },
        computed: {
            onRouts() {
                return this.$route.path
            },
            getRoutes() {
                return global.antRouter
            }
        },
        watch: {
            $route: "crumbs", //监听路由
        }
    }
</script>
<style lang="scss" scoped="scoped">
    .home {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .el-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    /* 左边 */
    .el-aside {
        background-color: #29726C;
        color: #333;
        text-align: center;
        overflow: hidden;
        width: 230px !important;
        transition: all .5s;
        /* line-height: 200px; */
    }

    // 图标颜色
    .el-icon-s-data,
    .el-icon-s-custom,
    .el-icon-edit-outline,
    .el-icon-pie-chart,
    .el-icon-s-tools {
        color: #FFFFFF;
    }

    /* 右边 */
    .el-main {
        background-color: #F0F3F5;
        color: #333;
        // text-align: center;
        // line-height: 160px;
        // margin: 0px !important;
        padding: 0px;
    }

    /* logo */
    .img {
        width: 189px;
        height: 97px;
        margin-top: 22px;
        margin-bottom: 40px;
    }

    /* 搜索菜单输入框 */
    #ipt ::v-deep .el-input__inner {
        width: 207px;
        height: 37px;
        margin: 36px 0px;
        background: #ffffff;
        opacity: 0.41;
        border-radius: 5px;
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;

    }

    #ipt ::v-deep .el-input__inner::-webkit-input-placeholder {
        color: #5e5e5e;
    }

    ::v-deep .el-input__suffix {
        margin-top: 2px;
        margin-right: 23px;
        width: 14px;
        color: #f4f4f4;
        font-size: 15px;
    }

    // 下拉菜单
    .el-menu {
        background-color: #304156;
        text-align: left;
        width: 230px !important;
        line-height: 200px !important;
        border-right: 0px !important;
        overflow: hidden;
        transition: all .5s;
    }

    .el-menu-item {
        width: 230px !important;
        margin-left: 20px;
        background-color: #0C5650 !important;

    }

    .el-menu-item:hover {
        background-color: #18BC9C !important;
    }

    ::v-deep .el-menu-item-group__title {
        padding-top: 2px;
    }
    .setup{
        margin: 0px;
        padding-left: 15px !important;
        background-color: #29726C !important;
    }
    // 子菜单
    .el-menu-item-group {
        background-color: #0C5650;
    }

    .el-menu-item.is-active {
        background: #18BC9C !important;
    }

    ::v-deep .el-submenu__icon-arrow.el-icon-arrow-down {
        color: #FFFFFF;
        font-size: 15px;
        font-weight: bold;
    }

    ::v-deep .el-submenu__title {
        padding-left: 15px !important;
    }

    .el-scrollbar {
        height: 100%;
        width: 230px !important;
        background-color: #29726C;
        text-align: center;
        transition: all .5s;
        // background-color: #304156 !important;
    }

    .scrollbar {
        width: 70px !important;
        transition: all .5s;
    }
    .not-text {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden !important;
        /* overflow-y: hidden !important; */
    }

    .timing {
        transition: all 0.5s;
    }

    //动画
    .cartoon img {
        transform: rotate(-90deg);
        transition: all 0.5s;
    }

    // 右边
    .el-header {
        background-color: #FFFFFF;
        color: #333;
        // text-align: center;
        height: 50px !important;
        line-height: 50px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.11) !important;
        // padding: 0px;

    }

    // 面包屑，历史记录
    .home-route {
        width: 82px;
        text-align: center;
        display: inline-block;
        color: #333333;
        padding-left: 5px;
        font-size: 14px;
        line-height: 50px;
        // margin-left: 10px;
        background: #F2F2F2;
    }

    .span_1 {
        height: 50px;
        width: 50px;
        text-align: center;
    }

    .span_1:hover {
        cursor: pointer;
    }

    #breadcrumb ::v-deep .el-breadcrumb__inner.is-link {
        text-align: center;
    }

    a {
        text-decoration: none;
        line-height: 25px;
        font-size: 14px;
        color: #999;
        padding: 0px 5px;
        display: inline-block;
        /* border: 1px solid #ccc; */
        /* margin-right: 10px; */
    }

    .active {
        color: #000;
        background: #F2F2F2;
    }

    .active a {
        color: #000;
    }


    // 右上下拉
    .el-header {
        padding-left: 0px !important;
    }

    .t-right {
        display: flex;
        align-items: center;
        margin-right: 32px;
        color: #666;
    }

    .t-right img {
        cursor: pointer;
    }

    .t-right i {
        vertical-align: middle;
        font-size: 20px;
        // padding: 0px 5px;
        cursor: pointer;
    }

    ::v-deep .el-dropdown-menu__item:focus,
    .el-dropdown-menu__item:not(.is-disabled):hover {
        background-color: #efefef;
        color: #333;
    }

    // 个人信息
    .personal {
        background: url(../../assets/geren.png) 8px 9px no-repeat;
        padding-left: 30px;
    }

    // 修改密码
    .modify {
        padding-left: 30px;
        background: url(../../assets/xiugaimim.png) 8px 9px no-repeat;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
    }

    // 退出
    .quit {
        padding-left: 30px;
        background: url(../../assets/tuichu.png) 8px 10px no-repeat;
    }

    // 个人信息弹出框
    ::v-deep .el-dialog__body {
        padding: 0px;
    }

    ::v-deep .el-dialog__title {
        font-weight: bold;
    }

    ::v-deep .el-dialog__footer {
        padding: 0px !important;
    }

    ::v-deep .el-dialog {
        width: 667px;
    }

    ::v-deep .el-dialog__header {
        // padding: 10px 20px;
    }

    .form-1 {
        padding: 10px !important;
        width: 500px !important;
        margin: 0px auto !important;
        // margin-top: 5px !important;
    }

    .form-1::v-deep .el-input__inner {
        width: 325px;
        height: 37px;
    }

    .form-1 ::v-deep .el-form-item__label {
        color: #000000;
    }

    .form-1 ::v-deep .el-input.is-disabled .el-input__inner {
        border: none;
        background-color: #fff;
    }

    /* 显示值 */
    .txtbox {
        height: 19px;
        margin-bottom: 10px;
        margin: 16px 0px;
        margin-right: 5px;
        font-size: 15px;

    }

    .txtbox span {
        display: inline-block;
        width: 100px;
        text-align: right;
    }

    .itp {
        border: none;
        outline: none;
        font-size: 15px;
        color: #000000;
        background-color: #FFFFFF;
        
    }

    //关闭按钮
    .guanbi {
        display: inline-block;
        width: 63px;
        height: 33px;
        background: #FAFAFA;
        border-radius: 3px;
        border: 1px solid #CCCCCC;
        margin: 0px 20px 10px 0px;
        cursor: pointer;
    }

    .footer {
        text-align: right;
    }
</style>
