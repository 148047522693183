import Vue from "vue";
import VueRouter from "vue-router";
// 首页
import Home from "../views/home/Home.vue";
// 登录页
const Login = () => import("@/views/login/Login");
const Dashboard = () => import("@/views/index/Dashboard");
Vue.use(VueRouter);

// 解决从“/”到“/dashboard”的导航被取消，使用一个新的导航的问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export const routes = [
  {
    path: "/", //登录页
    component: Login,
    hidden: true,
  },
  {
    path: "/login", //登录页
    component: Login,
    hidden: true,
  },
  {
    path: "/",
    component: Home,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        component: Dashboard,
        meta: {
          title: "首页", //首页
        },
      },
      {
        name: "newpage", //用于跳转刷新
        path: "/home/newpage",
        component: () => import("@/views/Emptypage/Newpage"),
      },
    ],
  },
  {
    path: "/basics",
    component: Home,
    redirect: "noredirect",
    alwaysShow: true,
    name: "basicsData",
    meta: {
      title: "基础数据",
    },
    children: [
      {
        path: "human",
        component: () => import("@/views/basics/Human"),
        name: "human",
        meta: {
          perms: [],
          title: "人力资源",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addHuman",
        component: () => import("@/views/basics/AddHuman"),
        name: "addHuman",
        meta: {
          perms: [],
          title: "人力资源新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editHuman",
        component: () => import("@/views/basics/EditHuman"),
        name: "editHuman",
        meta: {
          perms: [],
          title: "人力资源编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "framework",
        component: () => import("@/views/basics/Framework"),
        name: "framework",
        meta: {
          perms: [],
          title: "组织架构",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "teachingField",
        component: () => import("@/views/basics/TeachingField"),
        name: "teachingField",
        meta: {
          perms: [],
          title: "教学与考试场地",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addTeachingField",
        component: () => import("@/views/basics/AddTeachingField"),
        name: "addTeachingField",
        meta: {
          perms: [],
          title: "教学与考试场地新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editTeachingField",
        component: () => import("@/views/basics/EditTeachingField"),
        name: "editTeachingField",
        meta: {
          perms: [],
          title: "教学与考试场地编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "gradeData",
        component: () => import("@/views/basics/GradeData"),
        name: "gradeData",
        meta: {
          perms: [],
          title: "年级数据",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addGradeData",
        component: () => import("@/views/basics/AddGradeData"),
        name: "addGradeData",
        meta: {
          perms: [],
          title: "年级数据新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editGradeData",
        component: () => import("@/views/basics/EditGradeData"),
        name: "editGradeData",
        meta: {
          perms: [],
          title: "年级数据编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "subjectData",
        component: () => import("@/views/basics/SubjectData"),
        name: "subjectData",
        meta: {
          perms: [],
          title: "科目数据",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addSubjectData",
        component: () => import("@/views/basics/AddSubjectData"),
        name: "addSubjectData",
        meta: {
          perms: [],
          title: "学科数据新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editSubjectData",
        component: () => import("@/views/basics/EditSubjectData"),
        name: "editSubjectData",
        meta: {
          perms: [],
          title: "学科数据编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "rankData",
        component: () => import("@/views/basics/RankData"),
        name: "rankData",
        meta: {
          perms: [],
          title: "等级数据",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addRankData",
        component: () => import("@/views/basics/AddRankData"),
        name: "addRankData",
        meta: {
          perms: [],
          title: "等级数据新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editRankData",
        component: () => import("@/views/basics/EditRankData"),
        name: "editRankData",
        meta: {
          perms: [],
          title: "等级数据编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "schoolData",
        component: () => import("@/views/basics/SchoolData"),
        name: "schoolData",
        meta: {
          perms: [],
          title: "学校资料",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addSchoolData",
        component: () => import("@/views/basics/AddSchoolData"),
        name: "addSchoolData",
        meta: {
          perms: [],
          title: "学校资料新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editSchoolData",
        component: () => import("@/views/basics/EditSchoolData"),
        name: "editSchoolData",
        meta: {
          perms: [],
          title: "学校资料编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "organization",
        component: () => import("@/views/basics/Organization"),
        name: "organization",
        meta: {
          perms: [],
          title: "机构数据",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addOrganization",
        component: () => import("@/views/basics/AddOrganization"),
        name: "addOrganization",
        meta: {
          perms: [],
          title: "机构数据新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editOrganization",
        component: () => import("@/views/basics/EditOrganization"),
        name: "editOrganization",
        meta: {
          perms: [],
          title: "机构数据编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "cooperate",
        component: () => import("@/views/basics/Cooperate"),
        name: "cooperate",
        meta: {
          perms: [],
          title: "合作机构人员",
          noCache: false,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addCooperate",
        component: () => import("@/views/basics/AddCooperate"),
        name: "addCooperate",
        meta: {
          perms: [],
          title: "合作机构人员新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editCooperate",
        component: () => import("@/views/basics/EditCooperate"),
        name: "editCooperate",
        meta: {
          perms: [],
          title: "合作机构人员编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
    ],
  },
  {
    path: "/student",
    component: Home,
    redirect: "noredirect",
    alwaysShow: true,
    name: "studentManage",
    meta: {
      title: "学员管理",
    },
    children: [
      {
        path: "studentData",
        component: () => import("@/views/student/StudentData"),
        name: "studentData",
        meta: {
          perms: [],
          title: "学生基础数据",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addStudentData",
        component: () => import("@/views/student/AddStudentData"),
        name: "addStudentData",
        meta: {
          perms: [],
          title: "学生基础数据新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "studentNews",
        component: () => import("@/views/student/StudentNews"),
        name: "studentNews",
        meta: {
          perms: [],
          title: "学员信息",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editStudentData",
        component: () => import("@/views/student/EditStudentData"),
        name: "editStudentData",
        meta: {
          perms: [],
          title: "学生基础数据编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "studentEmpower",
        component: () => import("@/views/student/StudentEmpower"),
        name: "studentEmpower",
        meta: {
          perms: [],
          title: "学员授权",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "detailsStudentEmpower",
        component: () => import("@/views/student/DetailsStudentEmpower"),
        name: "detailsStudentEmpower",
        meta: {
          perms: [],
          title: "学员授权详情",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "retrieval",
        component: () => import("@/views/student/Retrieval"),
        name: "retrieval",
        meta: {
          perms: [],
          title: "检索学员资料",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "RetrievalEmpower",
        component: () => import("@/views/student/RetrievalEmpower"),
        name: "RetrievalEmpower",
        meta: {
          perms: [],
          title: "学员资料详情",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "batchProcessing",
        component: () => import("@/views/student/BatchProcessing"),
        name: "batchProcessing",
        meta: {
          perms: [],
          title: "学生证件",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "artwork",
        component: () => import("@/views/student/artwork"),
        name: "artwork",
        meta: {
          perms: [],
          title: "批量处理作品照片",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
    ],
  },
  {
    path: "/examination",
    component: Home,
    redirect: "noredirect",
    alwaysShow: true,
    name: "examinationManage",
    meta: {
      title: "考务管理",
    },
    children: [
      {
        path: "examinationSessions",
        component: () => import("@/views/examination/ExaminationSessions"),
        name: "examinationSessions",
        meta: {
          perms: [],
          title: "设置考试场次",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addExamBigClass",
        component: () => import("@/views/examination/AddExamBigClass"),
        name: "addExamBigClass",
        meta: {
          perms: [],
          title: "考试大类新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editSingle",
        component: () => import("@/views/examination/EditSingle"),
        name: "editSingle",
        meta: {
          perms: [],
          title: "单科考试编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editSynthetical",
        component: () => import("@/views/examination/EditSynthetical"),
        name: "editSynthetical",
        meta: {
          perms: [],
          title: "综合考场编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editExamBigClass",
        component: () => import("@/views/examination/EditExamBigClass"),
        name: "editExamBigClass",
        meta: {
          perms: [],
          title: "考试大类编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "singleData",
        component: () => import("@/views/examination/SingleData"),
        name: "singleData",
        meta: {
          // perms: [],
          title: "学员资料", //单科考试-学员资料
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "addSingle",
        component: () => import("@/views/examination/AddSingle"),
        name: "addSingle",
        meta: {
          perms: [],
          title: "单科考场新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "syntheticalData",
        component: () => import("@/views/examination/SyntheticalData"),
        name: "syntheticalData",
        meta: {
          perms: [],
          title: "学员资料", //综合考场-学员资料
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "addSynthetical",
        component: () => import("@/views/examination/AddSynthetical"),
        name: "addSynthetical",
        meta: {
          perms: [],
          title: "综合考场新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "registrationData",
        component: () => import("@/views/examination/RegistrationData"),
        name: "registrationData",
        meta: {
          perms: [],
          title: "导入报名数据",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "editImportTable",
        component: () => import("@/views/examination/EditImportTable"),
        name: "editImportTable",
        meta: {
          perms: [],
          title: "导入表编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "register",
        component: () => import("@/views/examination/Register"),
        name: "register",
        meta: {
          perms: [],
          title: "考生报考登记",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "registerForm",
        component: () => import("@/views/examination/RegisterForm"),
        name: "registerForm",
        meta: {
          perms: [],
          title: "考生报考登记表",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "registerDetails",
        component: () => import("@/views/examination/RegisterDetails"),
        name: "registerDetails",
        meta: {
          perms: [],
          title: "学员信息",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "addRegister",
        component: () => import("@/views/examination/AddRegister"),
        name: "addRegister",
        meta: {
          perms: [],
          title: "考生报考登记表新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editRegister",
        component: () => import("@/views/examination/EditRegister"),
        name: "editRegister",
        meta: {
          perms: [],
          title: "考生报考登记表编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "arrange",
        component: () => import("@/views/examination/Arrange"),
        name: "arrange",
        meta: {
          perms: [],
          title: "考生考场安排",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "studentAdmin",
        component: () => import("@/views/examination/StudentAdmin"),
        name: "studentAdmin",
        meta: {
          perms: [],
          title: "学员管理",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "addArrange",
        component: () => import("@/views/examination/AddArrange"),
        name: "addArrange",
        meta: {
          perms: [],
          title: "考生考场安排新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editArrange",
        component: () => import("@/views/examination/EditArrange"),
        name: "editArrange",
        meta: {
          perms: [],
          title: "考生考场安排编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "auditJournal",
        component: () => import("@/views/examination/AuditJournal"),
        name: "auditJournal",
        meta: {
          perms: [],
          title: "审核日记",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "admissionTicket",
        component: () => import("@/views/examination/AdmissionTicket"),
        name: "admissionTicket",
        meta: {
          perms: [],
          title: "打印准考证与点名表",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "studentDetails",
        component: () => import("@/views/examination/StudentDetails"),
        name: "studentDetails",
        meta: {
          perms: [],
          title: "书画学院打印准考证与点名表",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "nameList",
        component: () => import("@/views/examination/NameList"),
        name: "nameList",
        meta: {
          perms: [],
          title: "本场名单",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "testResult",
        component: () => import("@/views/examination/TestResult"),
        name: "testResult",
        meta: {
          perms: [],
          title: "录入学员考试成绩",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addTestResult",
        component: () => import("@/views/examination/AddTestResult"),
        name: "addTestResult",
        meta: {
          perms: [],
          title: "书画学院录入学员考试成绩表",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editTestResult",
        component: () => import("@/views/examination/EditTestResult"),
        name: "editTestResult",
        meta: {
          perms: [],
          title: "学员考试成绩编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "testResultDetails",
        component: () => import("@/views/examination/TestResultDetails"),
        name: "testResultDetails",
        meta: {
          perms: [],
          title: "学员信息",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "testResultMore",
        component: () => import("@/views/examination/TestResultMore"),
        name: "testResultMore",
        meta: {
          perms: [],
          title: "录入学员考试成绩更多",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "studentMsg",
        component: () => import("@/views/examination/StudentMsg"),
        name: "studentMsg",
        meta: {
          perms: [],
          title: "处理学生留言",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "editStudentMsg",
        component: () => import("@/views/examination/EditStudentMsg"),
        name: "editStudentMsg",
        meta: {
          perms: [],
          title: "处理留言编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
    ],
  },
  {
    path: "/educational",
    component: Home,
    redirect: "noredirect",
    alwaysShow: true,
    name: "educational",
    meta: {
      title: "教务管理",
    },
    children: [
      {
        path: "classCategory",
        component: () => import("@/views/educational/ClassCategory"),
        name: "classCategory",
        meta: {
          perms: [],
          title: "培训班级类别",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addClassCategory",
        component: () => import("@/views/educational/AddClassCategory"),
        name: "addClassCategory",
        meta: {
          perms: [],
          title: "培训班级类别新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editClassCategory",
        component: () => import("@/views/educational/EditClassCategory"),
        name: "editClassCategory",
        meta: {
          perms: [],
          title: "培训班级类别编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "classData",
        component: () => import("@/views/educational/ClassData"),
        name: "classData",
        meta: {
          perms: [],
          title: "培训班级类别--班级",
          noCache: false,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "teacherData",
        component: () => import("@/views/educational/TeacherData"),
        name: "teacherData",
        meta: {
          perms: [],
          title: "培训班级类别--授课老师",
          noCache: false,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addTeacher",
        component: () => import("@/views/educational/AddTeacher"),
        name: "addTeacher",
        meta: {
          perms: [],
          title: "授课老师新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editTeacher",
        component: () => import("@/views/educational/EditTeacher"),
        name: "editTeacher",
        meta: {
          perms: [],
          title: "授课老师编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "classList",
        component: () => import("@/views/educational/ClassList"),
        name: "classList",
        meta: {
          perms: [],
          title: "培训班级列表",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addClassList",
        component: () => import("@/views/educational/AddClassList"),
        name: "addClassList",
        meta: {
          perms: [],
          title: "培训班级列表新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editClassList",
        component: () => import("@/views/educational/EditClassList"),
        name: "editClassList",
        meta: {
          perms: [],
          title: "培训班级列表编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "studentInfo",
        component: () => import("@/views/educational/StudentInfo"),
        name: "studentInfo",
        meta: {
          perms: [],
          title: "学员信息",
          noCache: false,
          keepAlive: true, // 该路由不会被缓存
        },
      },
      {
        path: "addTimetable",
        component: () => import("@/views/educational/AddTimetable"),
        name: "addTimetable",
        meta: {
          perms: [],
          title: "排课表新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editTimetable",
        component: () => import("@/views/educational/EditTimetable"),
        name: "editTimetable",
        meta: {
          perms: [],
          title: "排课表编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "trainNewborn",
        component: () => import("@/views/educational/TrainNewborn"),
        name: "trainNewborn",
        meta: {
          perms: [],
          title: "培训新生注册",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addTrainNewborn",
        component: () => import("@/views/educational/AddTrainNewborn"),
        name: "addTrainNewborn",
        meta: {
          perms: [],
          title: "培训新生注册新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editTrainNewborn",
        component: () => import("@/views/educational/EditTrainNewborn"),
        name: "editTrainNewborn",
        meta: {
          perms: [],
          title: "培训新生注册编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "classmate",
        component: () => import("@/views/educational/Classmate"),
        name: "classmate",
        meta: {
          perms: [],
          title: "同班学员",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "billSummary",
        component: () => import("@/views/educational/BillSummary"),
        name: "billSummary",
        meta: {
          perms: [],
          title: "单据汇总",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "classRegister",
        component: () => import("@/views/educational/ClassRegister"),
        name: "classRegister",
        meta: {
          perms: [],
          title: "班级登记管理",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addClassRegister",
        component: () => import("@/views/educational/AddClassRegister"),
        name: "addClassRegister",
        meta: {
          perms: [],
          title: "班级登记管理新增",
          noCache: true,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editClassRegister",
        component: () => import("@/views/educational/EditClassRegister"),
        name: "editClassRegister",
        meta: {
          perms: [],
          title: "班级登记管理编辑",
          noCache: true,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "trainee",
        component: () => import("@/views/educational/Trainee"),
        name: "trainee",
        meta: {
          perms: [],
          title: "班级登记管理-学员",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addTrainee",
        component: () => import("@/views/educational/AddTrainee"),
        name: "addTrainee",
        meta: {
          perms: [],
          title: "学员新增",
          noCache: true,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editTrainee",
        component: () => import("@/views/educational/EditTrainee"),
        name: "editTrainee",
        meta: {
          perms: [],
          title: "学员编辑",
          noCache: true,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "collectionCode",
        component: () => import("@/views/educational/CollectionCode"),
        name: "collectionCode",
        meta: {
          perms: [],
          title: "收款码",
          noCache: true,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "classRegisterMore",
        component: () => import("@/views/educational/ClassRegisterMore"),
        name: "classRegisterMore",
        meta: {
          perms: [],
          title: "班级登记管理-更多",
          noCache: true,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "shiftTransfer",
        component: () => import("@/views/educational/ShiftTransfer"),
        name: "shiftTransfer",
        meta: {
          perms: [],
          title: "转班",
          noCache: true,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "retrievalStudents",
        component: () => import("@/views/educational/RetrievalStudents"),
        name: "retrievalStudents",
        meta: {
          perms: [],
          title: "检索辅导员学员名单",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "detailedTable",
        component: () => import("@/views/educational/DetailedTable"),
        name: "detailedTable",
        meta: {
          perms: [],
          title: "明细表",
          noCache: true,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "seeMore",
        component: () => import("@/views/educational/SeeMore"),
        name: "seeMore",
        meta: {
          perms: [],
          title: "更多",
          noCache: true,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "studentClockIn",
        component: () => import("@/views/educational/StudentClockIn"),
        name: "studentClockIn",
        meta: {
          perms: [],
          title: "培训学员考勤",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addStudentClockIn",
        component: () => import("@/views/educational/AddStudentClockIn"),
        name: "addStudentClockIn",
        meta: {
          perms: [],
          title: "培训学员考勤新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editStudentClockIn",
        component: () => import("@/views/educational/EditStudentClockIn"),
        name: "editStudentClockIn",
        meta: {
          perms: [],
          title: "培训学员考勤编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "pupil",
        component: () => import("@/views/educational/Pupil"),
        name: "pupil",
        meta: {
          perms: [],
          title: "培训学员考勤-学员",
          noCache: false,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addPupil",
        component: () => import("@/views/educational/AddPupil"),
        name: "addPupil",
        meta: {
          perms: [],
          title: "培训学员考勤-学员新增",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editPupil",
        component: () => import("@/views/educational/EditPupil"),
        name: "editPupil",
        meta: {
          perms: [],
          title: "培训学员考勤-学员编辑",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "studentClockInMore",
        component: () => import("@/views/educational/StudentClockInMore"),
        name: "studentClockInMore",
        meta: {
          perms: [],
          title: "培训学员考勤-更多",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "retrievalTable",
        component: () => import("@/views/educational/RetrievalTable"),
        name: "retrievalTable",
        meta: {
          perms: [],
          title: "检索学员出勤统计表",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "retrievalTableDetails",
        component: () => import("@/views/educational/RetrievalTableDetails"),
        name: "retrievalTableDetails",
        meta: {
          perms: [],
          title: "检索学员出勤统计表详情",
          noCache: false,
          keepAlive: false, // 该路由不会会被缓存
        },
      },
      {
        path: "censusTable",
        component: () => import("@/views/educational/CensusTable"),
        name: "censusTable",
        meta: {
          perms: [],
          title: "统计表",
          noCache: false,
          keepAlive: false, // 该路由不会会被缓存
        },
      },
      {
        path: "siteUsage",
        component: () => import("@/views/educational/SiteUsage"),
        name: "siteUsage",
        meta: {
          perms: [],
          title: "教学场地使用情况",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "siteUsageCensus",
        component: () => import("@/views/educational/SiteUsageCensus"),
        name: "siteUsageCensus",
        meta: {
          perms: [],
          title: "教学场地使用情况统计表",
          noCache: false,
          keepAlive: false, // 该路由不会会被缓存
        },
      },
    ],
  },
  {
    path: "/finance",
    component: Home,
    redirect: "noredirect",
    alwaysShow: true,
    name: "finance",
    meta: {
      title: "财务作业",
    },
    children: [],
  },
  {
    path: "/dailyAffairs",
    component: Home,
    redirect: "noredirect",
    alwaysShow: true,
    name: "dailyAffairs",
    meta: {
      title: "日常事务",
    },
    children: [],
  },
  {
    path: "/stockControl",
    component: Home,
    redirect: "noredirect",
    alwaysShow: true,
    name: "stockControl",
    meta: {
      title: "库存管理",
    },
    children: [],
  },
  {
    path: "/business",
    component: Home,
    redirect: "noredirect",
    alwaysShow: true,
    name: "businessCensus",
    meta: {
      title: "业务统计",
    },
    children: [
      {
        path: "examinationRoom",
        component: () => import("@/views/business/ExaminationRoom"),
        name: "examinationRoom",
        meta: {
          perms: [],
          title: "考场统计表",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "statisticsDetails",
        component: () => import("@/views/business/StatisticsDetails"),
        name: "statisticsDetails",
        meta: {
          perms: [],
          title: "考场统计表详情",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "examineeCensus",
        component: () => import("@/views/business/ExamineeCensus"),
        name: "examineeCensus",
        meta: {
          perms: [],
          title: "考生统计表",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "examineeDetails",
        component: () => import("@/views/business/ExamineeDetails"),
        name: "examineeDetails",
        meta: {
          perms: [],
          title: "考生统计表详情",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "examineeResult",
        component: () => import("@/views/business/ExamineeResult"),
        name: "examineeResult",
        meta: {
          perms: [],
          title: "考生成绩表",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "resultDetails",
        component: () => import("@/views/business/ResultDetails"),
        name: "resultDetails",
        meta: {
          perms: [],
          title: "考生成绩表详情",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "resultMore",
        component: () => import("@/views/business/ResultMore"),
        name: "resultMore",
        meta: {
          perms: [],
          title: "考生成绩表更多",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "examineeSubject",
        component: () => import("@/views/business/ExamineeSubject"),
        name: "examineeSubject",
        meta: {
          perms: [],
          title: "考生科目等级统计表",
          noCache: true,
          keepAlive: true, // 该路由不会被缓存
        },
      },
      {
        path: "detailedOne",
        component: () => import("@/views/business/detailedOne"),
        name: "detailedOne",
        meta: {
          perms: [],
          title: "明细表",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "detailedTwo",
        component: () => import("@/views/business/detailedTwo"),
        name: "detailedTwo",
        meta: {
          perms: [],
          title: "明细表",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
    ],
  },
  {
    path: "/rbac",
    component: Home,
    redirect: "noredirect",
    alwaysShow: true,
    name: "rbac",
    meta: {
      title: "权限管理",
    },
    children: [
      {
        path: "roles",
        component: () => import("@/views/authorization/Roles"),
        name: "roles",
        meta: {
          perms: [],
          title: "角色管理",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "functional",
        component: () => import("@/views/authorization/Functional"),
        name: "functional",
        meta: {
          perms: [],
          title: "添加功能权限",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editfunctional",
        component: () => import("@/views/authorization/EditFunctional"),
        name: "editfunctional",
        meta: {
          perms: [],
          title: "编辑功能权限",
          noCache: false,
          keepAlive: false, // 该路由不会被缓存
        },
      },
    ],
  },
  {
    path: "/websiteSet",
    component: Home,
    redirect: "noredirect",
    alwaysShow: true,
    name: "websiteSet",
    meta: {
      title: "官网设置",
    },
    children: [
      {
        path: "newsDynamic",
        component: () => import("@/views/websiteSet/NewsDynamic"),
        name: "newsDynamic",
        meta: {
          perms: [],
          title: "新闻动态",
          noCache: true,
          keepAlive: true, // 该路由会被缓存
        },
      },
      {
        path: "addnewsDynamic",
        component: () => import("@/views/websiteSet/AddNewsDynamic"),
        name: "addnewsDynamic",
        meta: {
          perms: [],
          title: "新闻动态新增",
          noCache: true,
          keepAlive: false, // 该路由不会被缓存
        },
      },
      {
        path: "editnewsDynamic",
        component: () => import("@/views/websiteSet/EditNewsDynamic"),
        name: "editnewsDynamic",
        meta: {
          perms: [],
          title: "新闻动态编辑",
          noCache: true,
          keepAlive: false, // 该路由不会被缓存
        },
      },
    ],
  },
];

// router.beforeEach((to, from, next) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title;
//   }
//   next();
// })

// 固定的路由表
// export const fixedRouter = [
//  //    {
// 	// 	path: '/',
// 	// 	component: Login,
// 	// 	hidden: true
// 	// },
// 	{
// 		path: '/',
// 		component: Home, //整体页面的布局(包含左侧菜单跟主内容区域)
// 		redirect: "/dashboard",
// 		children: [{
// 				path: "/dashboard",
// 				component: Dashboard,
// 				meta: {
// 					title: '首页', //首页
// 					// roles: [], //当前菜单哪些角色可以看到
// 					// icon: 'el-icon-menu' //菜单左侧的icon图标
// 				}
// 			},

// 		]
// 	},
// ]
// // 需要权限判断展示的路由
// export const permissionRouter=[{
//     path:"/basics",
//     component:Home,
//     redirect: 'noredirect',
//     alwaysShow: true,
//     name:"basicsData",
//     meta:{
//         title:'基础数据'
//     },
//     children:[{
//         path:"human",
//         component:()=> import('@/views/basics/Human'),
//         name:"human",
//         meta:{
//             perms: [],
//             title:'人力资源',
//             noCache: true
//         }
//     },{
//    path:"addHuman",
//     component:()=> import('@/views/basics/AddHuman'),
//     name:"addHuman",
//     meta:{
//         perms: [],
//         title:'人力资源新增',
//         noCache: false
//     }
// },
// {
//     path:"editHuman",
//     component:()=> import('@/views/basics/EditHuman'),
//     name:"editHuman",
//     meta:{
//         perms: [],
//         title:'人力资源编辑',
//         noCache: false
//     }
// },
//     {
//         path:"framework",
//         component:()=> import('@/views/basics/Framework'),
//         name:"framework",
//         meta:{
//             perms: [],
//             title:'组织架构',
//             noCache: true
//         }
//     },
//     {
//         path:"teachingField",
//         component:()=> import('@/views/basics/TeachingField'),
//         name:"teachingField",
//         meta:{
//             perms: [],
//             title:'教学与考试场地',
//             noCache: true
//         }
//     },
// {
//     path: "addTeachingField",
//     component: () => import('@/views/basics/AddTeachingField'),
//     name: "addTeachingField",
//     meta: {
//         perms: [],
//         title: '教学与考试场地新增',
//         noCache: false
//     }
// }, {
//     path: "editTeachingField",
//     component: () => import('@/views/basics/EditTeachingField'),
//     name: "editTeachingField",
//     meta: {
//         perms: [],
//         title: '教学与考试场地编辑',
//         noCache: false
//     }
// },
//     {
//         path:"gradeData",
//         component:()=> import('@/views/basics/GradeData'),
//         name:"gradeData",
//         meta:{
//             perms: [],
//             title:'年级数据',
//             noCache: true
//         }
//     }, {
//     path: "addGradeData",
//     component: () => import('@/views/basics/AddGradeData'),
//     name: "addGradeData",
//     meta: {
//         perms: [],
//         title: '年级数据新增',
//         noCache: false
//     }
// }, {
//     path: "editGradeData",
//     component: () => import('@/views/basics/EditGradeData'),
//     name: "editGradeData",
//     meta: {
//         perms: [],
//         title: '年级数据编辑',
//         noCache: false
//     }
// }, {
//         path:"subjectData",
//         component:()=> import('@/views/basics/SubjectData'),
//         name:"subjectData",
//         meta:{
//             perms: [],
//             title:'学科数据',
//             noCache: true
//         }
//     }, {
//     path: "addSubjectData",
//     component: () => import('@/views/basics/AddSubjectData'),
//     name: "addSubjectData",
//     meta: {
//         perms: [],
//         title: '学科数据新增',
//         noCache: false
//     }
// }, {
//     path: "editSubjectData",
//     component: () => import('@/views/basics/EditSubjectData'),
//     name: "editSubjectData",
//     meta: {
//         perms: [],
//         title: '学科数据编辑',
//         noCache: false
//     }
// },{
//         path:"rankData",
//         component:()=> import('@/views/basics/RankData'),
//         name:"rankData",
//         meta:{
//             perms: [],
//             title:'等级数据',
//             noCache: true
//         }
//     }, {
//     path: "addRankData",
//     component: () => import('@/views/basics/AddRankData'),
//     name: "addRankData",
//     meta: {
//         perms: [],
//         title: '等级数据新增',
//         noCache: false
//     }
// }, {
//     path: "editRankData",
//     component: () => import('@/views/basics/EditRankData'),
//     name: "editRankData",
//     meta: {
//         perms: [],
//         title: '等级数据编辑',
//         noCache: false
//     }
// },{
//         path:"schoolData",
//         component:()=> import('@/views/basics/SchoolData'),
//         name:"schoolData",
//         meta:{
//             perms: [],
//             title:'学校资料',
//             noCache: true
//         }
//     }, {
//     path: "addSchoolData",
//     component: () => import('@/views/basics/AddSchoolData'),
//     name: "addSchoolData",
//     meta: {
//         perms: [],
//         title: '学校资料新增',
//         noCache: false
//     }
// }, {
//     path: "editSchoolData",
//     component: () => import('@/views/basics/EditSchoolData'),
//     name: "editSchoolData",
//     meta: {
//         perms: [],
//         title: '学校资料编辑',
//         noCache: false
//     }
// },{
//         path:"organization",
//         component:()=> import('@/views/basics/Organization'),
//         name:"organization",
//         meta:{
//             perms: [],
//             title:'机构数据',
//             noCache: true
//         }
//     }, {
//     path: "addOrganization",
//     component: () => import('@/views/basics/AddOrganization'),
//     name: "addOrganization",
//     meta: {
//         perms: [],
//         title: '机构数据新增',
//         noCache: false
//     }
// }, {
//     path: "editOrganization",
//     component: () => import('@/views/basics/EditOrganization'),
//     name: "editOrganization",
//     meta: {
//         perms: [],
//         title: '机构数据编辑',
//         noCache: false
//     }
// },{
//         path:"cooperate",
//         component:()=> import('@/views/basics/Cooperate'),
//         name:"cooperate",
//         meta:{
//             perms: [],
//             title:'合作机构人员',
//             noCache: true
//         }
//     }, {
// path: "addCooperate",
//     component: () => import('@/views/basics/AddCooperate'),
//     name: "addCooperate",
//     meta: {
//         perms: [],
//         title: '合作机构人员新增',
//         noCache: false
//     }
// }, {
//     path: "editCooperate",
//     component: () => import('@/views/basics/EditCooperate'),
//     name: "editCooperate",
//     meta: {
//         perms: [],
//         title: '合作机构人员编辑',
//         noCache: false
//     }
// }]
// },{
//     path:"/student",
//     component:Home,
//     redirect: 'noredirect',
//     alwaysShow: true,
//     name:"studentManage",
//     meta:{
//         title:'学员管理'
//     },
//      children:[{
//     path: "studentData",
//     component: () => import('@/views/student/StudentData'),
//     name: "studentData",
//     meta: {
//         perms: [],
//         title: '学生基础数据',
//         noCache: true
//     }
// }, {
//     path: "addStudentData",
//     component: () => import('@/views/student/AddStudentData'),
//     name: "addStudentData",
//     meta: {
//         perms: [],
//         title: '学生基础数据新增',
//         noCache: false
//     }
// }, {
//     path: "editStudentData",
//     component: () => import('@/views/student/EditStudentData'),
//     name: "editStudentData",
//     meta: {
//         perms: [],
//         title: '学生基础数据编辑',
//         noCache: false
//     }
// }, {
//     path: "studentEmpower",
//     component: () => import('@/views/student/StudentEmpower'),
//     name: "studentEmpower",
//     meta: {
//         perms: [],
//         title: '学员授权',
//         noCache: true
//     }
// }, {
//     path: "detailsStudentEmpower",
//     component: () => import('@/views/student/DetailsStudentEmpower'),
//     name: "detailsStudentEmpower",
//     meta: {
//         perms: [],
//         title: '学员授权详情',
//         noCache: false
//     }
// }, {
//     path: "retrieval",
//     component: () => import('@/views/student/Retrieval'),
//     name: "retrieval",
//     meta: {
//         perms: [],
//         title: '检索学员资料',
//         noCache: true
//     }
// }, {
//     path: "batchProcessing",
//     component: () => import('@/views/student/BatchProcessing'),
//     name: "batchProcessing",
//     meta: {
//         perms: [],
//         title: '批量处理学员相片',
//         noCache: true
//     }
// }]
// },{
//     path:"/examination",
//     component:Home,
//     redirect: 'noredirect',
//     alwaysShow: true,
//     name:"examinationManage",
//     meta:{
//         title:'考务管理'
//     },
//      children:[
//     {
//         path: "examinationSessions",
//         component: () => import('@/views/examination/ExaminationSessions'),
//         name: "examinationSessions",
//         meta: {
//             perms: [],
//             title: '设置考试场次',
//             noCache: true
//         }
//     },
// {
//            path: "addExamBigClass",
//            component: () => import('@/views/examination/AddExamBigClass'),
//            name: "addExamBigClass",
//            meta: {
//                perms: [],
//                title: '考试大类新增',
//                noCache: false
//            }
//        },
// {
//             path: "singleData",
//             component: () => import('@/views/examination/SingleData'),
//             name: "singleData",
//             meta: {
//                 perms: [],
//                 title: '学员资料',//单科考试-学员资料
//                 noCache: false
//             }
//         },
//         {
//             path: "addSingle",
//             component: () => import('@/views/examination/AddSingle'),
//             name: "addSingle",
//             meta: {
//                 perms: [],
//                 title: '单科考场新增',
//                 noCache: false
//             }
//         },
//         {
//             path: "syntheticalData",
//             component: () => import('@/views/examination/SyntheticalData'),
//             name: "syntheticalData",
//             meta: {
//                 perms: [],
//                 title: '学员资料',//综合考场-学员资料
//                 noCache: false
//             }
//         },
//         {
//             path: "addSynthetical",
//             component: () => import('@/views/examination/AddSynthetical'),
//             name: "addSynthetical",
//             meta: {
//                 perms: [],
//                 title: '综合考场新增',
//                 noCache: false
//             }
//         },
//     {
//         path: "registrationData",
//         component: () => import('@/views/examination/RegistrationData'),
//         name: "registrationData",
//         meta: {
//             perms: [],
//             title: '导入报名数据',
//             noCache: true
//         }
//     },
//     {
//         path: "register",
//         component: () => import('@/views/examination/Register'),
//         name: "register",
//         meta: {
//             perms: [],
//             title: '考生报考登记',
//             noCache: true
//         }
//     },
// {
//             path: "registerForm",
//             component: () => import('@/views/examination/RegisterForm'),
//             name: "registerForm",
//             meta: {
//                 perms: [],
//                 title: '考生报考登记表',
//                 noCache: false
//             }
//         },
//         {
//             path: "registerDetails",
//             component: () => import('@/views/examination/RegisterDetails'),
//             name: "registerDetails",
//             meta: {
//                 perms: [],
//                 title: '学员信息',
//                 noCache: false
//             }
//         },
// {
//            path: "addRegister",
//            component: () => import('@/views/examination/AddRegister'),
//            name: "addRegister",
//            meta: {
//                perms: [],
//                title: '考生报考登记表新增',
//                noCache: false
//            }
//        },
//        {
//            path: "editRegister",
//            component: () => import('@/views/examination/EditRegister'),
//            name: "editRegister",
//            meta: {
//                perms: [],
//                title: '考生报考登记表编辑',
//                noCache: false
//            }
//        },
//     {
//         path: "arrange",
//         component: () => import('@/views/examination/Arrange'),
//         name: "arrange",
//         meta: {
//             perms: [],
//             title: '考生考场安排',
//             noCache: true
//         }
//     },
// {
//             path: "studentAdmin",
//             component: () => import('@/views/examination/StudentAdmin'),
//             name: "studentAdmin",
//             meta: {
//                 perms: [],
//                 title: '学员管理',
//                 noCache: false
//             }
//         },
// {
//             path: "addArrange",
//             component: () => import('@/views/examination/AddArrange'),
//             name: "addArrange",
//             meta: {
//                 perms: [],
//                 title: '考生考场安排新增',
//                 noCache: false
//             }
//         },
//         {
//             path: "editArrange",
//             component: () => import('@/views/examination/EditArrange'),
//             name: "editArrange",
//             meta: {
//                 perms: [],
//                 title: '考生考场安排编辑',
//                 noCache: false
//             }
//         },
// {
//            path: "addStudent",
//            component: () => import('@/views/examination/AddStudent'),
//            name: "addStudent",
//            meta: {
//                perms: [],
//                title: '添加学员',
//                noCache: false
//            }
//        },
// {
//             path: "auditJournal",
//             component: () => import('@/views/examination/AuditJournal'),
//             name: "auditJournal",
//             meta: {
//                 perms: [],
//                 title: '审核日记',
//                 noCache: false
//             }
//         },
//     {
//         path: "admissionTicket",
//         component: () => import('@/views/examination/AdmissionTicket'),
//         name: "admissionTicket",
//         meta: {
//             perms: [],
//             title: '打印准考证与点名表',
//             noCache: true
//         }
//     },
// {
//             path: "studentDetails",
//             component: () => import('@/views/examination/StudentDetails'),
//             name: "studentDetails",
//             meta: {
//                 perms: [],
//                 title: '书画学院打印准考证与点名表',
//                 noCache: false
//             }
//         },
//         {
//             path: "nameList",
//             component: () => import('@/views/examination/NameList'),
//             name: "nameList",
//             meta: {
//                 perms: [],
//                 title: '本场名单',
//                 noCache: false
//             }
//         },
//     {
//         path: "testResult",
//         component: () => import('@/views/examination/TestResult'),
//         name: "testResult",
//         meta: {
//             perms: [],
//             title: '录入学员考试成绩',
//             noCache: true
//         }
//     },
// {
//            path: "addTestResult",
//            component: () => import('@/views/examination/AddTestResult'),
//            name: "addTestResult",
//            meta: {
//                perms: [],
//                title: '书画学院录入学员考试成绩表',
//                noCache: false
//            }
//        },
//        {
//            path: "editTestResult",
//            component: () => import('@/views/examination/EditTestResult'),
//            name: "editTestResult",
//            meta: {
//                perms: [],
//                title: '学员考试成绩编辑',
//                noCache: false
//            }
//        },
//        {
//            path: "testResultDetails",
//            component: () => import('@/views/examination/TestResultDetails'),
//            name: "testResultDetails",
//            meta: {
//                perms: [],
//                title: '学员信息',
//                noCache: false
//            }
//        },
// {
//     path: "testResultMore",
//     component: () => import('@/views/examination/TestResultMore'),
//     name: "testResultMore",
//     meta: {
//         perms: [],
//         title: '录入学员考试成绩更多',
//         noCache: false
//     }
// },
//     {
//         path: "studentMsg",
//         component: () => import('@/views/examination/StudentMsg'),
//         name: "studentMsg",
//         meta: {
//             perms: [],
//             title: '处理学生留言',
//             noCache: true
//         }
//     },
// {
//     path: "editStudentMsg",
//     component: () => import('@/views/examination/EditStudentMsg'),
//     name: "editStudentMsg",
//     meta: {
//         perms: [],
//         title: '处理留言编辑',
//         noCache: true
//     }
// }
// ]
// },{
//     path: "/educational",
//     component: Home,
//     redirect: 'noredirect',
//     alwaysShow: true,
//     name: "educational",
//     meta: {
//         title: '教务管理'
//     },
//     children: [{
//     path: "classCategory",
//     component: () => import('@/views/educational/ClassCategory'),
//     name: "classCategory",
//     meta: {
//         perms: [],
//         title: '培训班级类别',
//         noCache: true
//     }
// },{
//     path: "classList",
//     component: () => import('@/views/educational/ClassList'),
//     name: "classList",
//     meta: {
//         perms: [],
//         title: '培训班级列表',
//         noCache: true
//     }
// },{
//     path: "trainNewborn",
//     component: () => import('@/views/educational/TrainNewborn'),
//     name: "trainNewborn",
//     meta: {
//         perms: [],
//         title: '培训新生注册',
//         noCache: true
//     }
// },{
//     path: "classRegister",
//     component: () => import('@/views/educational/ClassRegister'),
//     name: "classRegister",
//     meta: {
//         perms: [],
//         title: '班级登记管理',
//         noCache: true
//     }
// },{
//     path: "retrievalStudents",
//     component: () => import('@/views/educational/RetrievalStudents'),
//     name: "retrievalStudents",
//     meta: {
//         perms: [],
//         title: '检索辅导员学员名单',
//         noCache: true
//     }
// },{
//     path: "studentClockIn",
//     component: () => import('@/views/educational/StudentClockIn'),
//     name: "studentClockIn",
//     meta: {
//         perms: [],
//         title: '培训学员考勤',
//         noCache: true
//     }
// },{
//     path: "retrievalTable",
//     component: () => import('@/views/educational/RetrievalTable'),
//     name: "retrievalTable",
//     meta: {
//         perms: [],
//         title: '检索学员出勤统计表',
//         noCache: true
//     }
// },{
//     path: "siteUsage",
//     component: () => import('@/views/educational/SiteUsage'),
//     name: "siteUsage",
//     meta: {
//         perms: [],
//         title: '教学场地使用情况',
//         noCache: true
//     }
// }]
// },
// {
//         path: "/finance",
//         component: Home,
//         redirect: 'noredirect',
//         alwaysShow: true,
//         name: "finance",
//         meta: {
//             title: '财务作业'
//         },
//         children: []
//     },{
//         path: "/dailyAffairs",
//         component: Home,
//         redirect: 'noredirect',
//         alwaysShow: true,
//         name: "dailyAffairs",
//         meta: {
//             title: '日常事务'
//         },
//         children: []
//     },{
//         path: "/stockControl",
//         component: Home,
//         redirect: 'noredirect',
//         alwaysShow: true,
//         name: "stockControl",
//         meta: {
//             title: '库存管理'
//         },
//         children: []
//     },{
//     path:"/business",
//     component:Home,
//     redirect: 'noredirect',
//     alwaysShow: true,
//     name:"businessCensus",
//     meta:{
//         title:'业务统计'
//     },
//      children:[{
//     path: "examinationRoom",
//     component: () => import('@/views/business/ExaminationRoom'),
//     name: "examinationRoom",
//     meta: {
//         perms: [],
//         title: '考场统计表',
//         noCache: true
//     }
// }, {
//     path: "statisticsDetails",
//     component: () => import('@/views/business/StatisticsDetails'),
//     name: "statisticsDetails",
//     meta: {
//         perms: [],
//         title: '考场统计表',
//         noCache: true
//     }
// }, {
//     path: "examineeCensus",
//     component: () => import('@/views/business/ExamineeCensus'),
//     name: "examineeCensus",
//     meta: {
//         perms: [],
//         title: '考生统计表',
//         noCache: true
//     }
// },  {
//     path: "examineeDetails",
//     component: () => import('@/views/business/ExamineeDetails'),
//     name: "examineeDetails",
//     meta: {
//         perms: [],
//         title: '考生统计表详情',
//         noCache: false
//     }
// },{
//     path: "examineeResult",
//     component: () => import('@/views/business/ExamineeResult'),
//     name: "examineeResult",
//     meta: {
//         perms: [],
//         title: '考生成绩表',
//         noCache: true
//     }
// },{
//     path: "resultDetails",
//     component: () => import('@/views/business/ResultDetails'),
//     name: "resultDetails",
//     meta: {
//         perms: [],
//         title: '考生成绩表详情',
//         noCache: false
//     }
// },{
//     path: "resultMore",
//     component: () => import('@/views/business/ResultMore'),
//     name: "resultMore",
//     meta: {
//         perms: [],
//         title: '考生成绩表更多',
//         noCache: false
//     }
// }, {
//     path: "examineeSubject",
//     component: () => import('@/views/business/ExamineeSubject'),
//     name: "examineeSubject",
//     meta: {
//         perms: [],
//         title: '考生科目等级统计表',
//         noCache: true
//     }
// }]
// },{
//     path:"/rbac",
//     component:Home,
//     redirect: 'noredirect',
//     alwaysShow: true,
//     name:"rbac",
//     meta:{
//         title:'权限管理'
//     },
//      children:[
//     {
//         path: "roles",
//         component: () => import('@/views/authorization/Roles'),
//         name: "roles",
//         meta: {
//             perms: [],
//             title: '角色管理',
//             noCache: true
//         }
//     },{
//         path: "functional",
//         component: () => import('@/views/authorization/Functional'),
//         name: "functional",
//         meta: {
//             perms: [],
//             title: '功能权限',
//             noCache: false
//         }
//     }
// ]
// }]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // routes: fixedRouter
});

export default router;
