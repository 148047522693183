<template>
    <div id="app">
        <div id="nav">
            <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
        </div>
        <!-- <router-view /> -->
        <!-- 判断页面是否缓存 -->
        <keep-alive v-if="isLoggedIn">
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        
        <router-view v-if="!$route.meta.keepAlive||!isLoggedIn"></router-view>
    </div>
</template>
<script>
    export default{
        data() {
            return{
                isLoggedIn: false,
            }
        },
        
        watch:{
            $route(to,from){
                let token = this.$cookies.get('token') || '';
                if(token){
                    this.isLoggedIn = true
                }else{
                    this.isLoggedIn = false
                }
            }
        }
    }
</script>
<style lang="scss">
    * {
        margin: 0px;
        padding: 0px;
    }

    html,
    body,
    #app {
        width: 100%;
        height: 100vh;
    }
    .textArea-width {
        width: 1220px !important;
    }
    .text-ipt{
        width: 760px !important;
    }
    .text-data{
        width: 1160px !important;
    }
    
    @media screen and (max-width: 1650px) {
        .textArea-width {
            width: 760px !important;
        }
    }
    @media screen and (max-width: 1650px) {
        .textArea-width {
            width: 760px !important;
        }
        .text-data{
            width: 730px !important;
        }
    }
    @media screen and (max-width: 1190px) {
        .textArea-width {
            width: 300px !important;  
        }
        .text-ipt{
            width: 300px !important;
        }
        .text-data{
            width: 300px !important;
        }
        
    }
    @media screen and (max-width: 1024px) {
        .note-width {
            display: block;
            margin-left: 0px !important;
        }
    }
    // #app {
    //   font-family: Avenir, Helvetica, Arial, sans-serif;
    //   -webkit-font-smoothing: antialiased;
    //   -moz-osx-font-smoothing: grayscale;
    //   text-align: center;
    //   color: #2c3e50;
    // }

    // #nav {
    //   padding: 30px;

    //   a {
    //     font-weight: bold;
    //     color: #2c3e50;

    //     &.router-link-exact-active {
    //       color: #42b983;
    //     }
    //   }
    // }
</style>
