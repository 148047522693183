import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Cookies from 'js-cookie'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import $requst from './utils/Http.js'

import axios from 'axios'
import VueAxios from 'vue-axios'

import './utils/global'//全局

import pinyin from '@/utils/Convert_Pinyin.js'


import '@/permission.js'// 控制路由表的js文件

import Print from 'vue-print-nb'



Vue.prototype.$request = $requst
Vue.prototype.$pinyin = pinyin

Vue.prototype.$cookies = Cookies

Vue.use(VueAxios, axios)
Vue.use(ElementUI);
Vue.use(Print);
Vue.config.productionTip = false;


router.beforeEach((to,from,next)=>{
        if(to.path=='/login'||to.path=='/'){
            next()
        }else{
           
           if(Cookies.get('token')){
               next()
           }else{
               next({path:'/'}) 
           }
        }
})




new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");