import axios from "axios";
import Cookies from "js-cookie";
import router from "@/router";
import qs from "qs";

// const baseURL = 'http://shuhuaxueyuan.com/index.php'//本地地址

const baseURL = "https://artxt.szart.cn/api/public/index.php"; //线上地址
// const baseURL = "http://shxy.xyzxkj.com/api/public/index.php"; // 公司演示站线上地址

// const baseURL = process.env.NODE_ENV === "development" ? "http://shuhuaxueyuan.com/index.php":"https://artxt.xinyuad.net/api/public/index.php"

// console.log(baseURL);

// 1.创建一个新的请求实例
// const instance = axios.create({
//     baseURL: 'http://shuhuaxueyuan.com/index.php', //url
//     timeout: 5000    //延时
// });

const instance = function (url) {
  // console.log(url)
  return axios({
    method: url.method || "get",
    url: baseURL + url.url,
    headers: {
      token: this.$cookies.get("token") || "",
    },
    data: url.data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {});
};

// 2.添加拦截器
// 请求拦截
// instance.interceptors.request.use(config => {
//         config.headers['X-Litemall-Admin-Token'] = this.$cookies.get('token')
//         // config.headers['content-type']="application/x-www-form-urlencoded";
//         return config;
//     }),
//     function(error) {
//         // 对请求错误做些什么
//         return Promise.reject(error);
//     }

// 添加响应拦截器
// instance.interceptors.response.use(response => {
//     // console.log(response)
//     // if(response){
//     //     router.push('/login')
//     // }

//     return response;
// },error=> {
//     // 对响应错误做点什么
//     return Promise.reject(error);
// })

// async function login(response) {}

// 导出
export default instance;
